import { BoxEntry } from "../types";
import styled from "styled-components";
import { List, WindowScroller } from "react-virtualized";
import { BoxEntryRow } from "./BoxEntryRow";

interface BoxEntryTableProps {
    boxEntries: BoxEntry[]
    setBoxEntry: (id: string, boxEntry: BoxEntry) => void
}

export function BoxEntryTable({ boxEntries, setBoxEntry }: BoxEntryTableProps) {
    function isLastInGroup(index: number) {
        return boxEntries[index + 1] && (
            boxEntries[index + 1].nationalPokedexNumber !== 
            boxEntries[index].nationalPokedexNumber
        );
    }

    return (
        <Wrapper>
            <WindowScroller>
                {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                    <div ref={registerChild}>
                        <List
                            autoHeight
                            height={height}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            rowCount={boxEntries.length}
                            rowHeight={54}
                            rowRenderer={({ index, style }) => (
                                <BoxEntryRow
                                    key={boxEntries[index].id} 
                                    style={style} 
                                    boxEntry={boxEntries[index]} 
                                    isLastInGroup={isLastInGroup(index)} 
                                    onChange={(boxEntry) => 
                                        setBoxEntry(boxEntries[index].id, boxEntry)
                                    }
                                />
                            )}
                            scrollTop={scrollTop}
                            width={1}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%"
                            }}
                            style={{
                                width: "100%",
                                outline: 'none'
                            }}
                            overscanRowCount={50}
                        />
                    </div>
                )}
            </WindowScroller>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(27,31,35,0.15);
    box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
`;