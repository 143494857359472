import styled from "styled-components";

interface PokemonImageProps {
    url: string,
    silhouette?: boolean,
    size?: "mini" | "medium"
}

export function PokemonImage({ 
    url, 
    silhouette = false,
    size = "mini"
}: PokemonImageProps) {
    return <Icon src={url} className={silhouette ? `silhouette ${size}` : size}/>
}

const Icon = styled.img`
    &.mini {
        width: 40px;
        height: 40px;
    }

    &.medium {
        width: 80px;
        height: 80px;
    }

    &.silhouette {
        -webkit-filter: grayscale(100%) brightness(0);
        filter: grayscale(100%) brightness(0);
        opacity: 0.2;
    }
`;