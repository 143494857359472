import styled from "styled-components";
import emptyMarkIcon from "../assets/img/generation_marks/empty_mark.png"

export function EmptyGenerationMarkIcon() {
    return <Icon src={emptyMarkIcon} alt="No Mark Set" />
}

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;