import { ViewLayout } from "../types";
import { Dropdown } from "./Dropdown";
import { faTh, faThList } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "./IconButton";

interface ViewLayoutSelectProps {
    value: ViewLayout
    onChange: (value: ViewLayout) => void
}

export function ViewLayoutSelect({ 
    value, 
    onChange
}: ViewLayoutSelectProps) {
    const options = [
        value, 
        ...[ViewLayout.List, ViewLayout.Boxes].filter(b => b !== value)
    ];
    return (
        <Dropdown
            trigger={<LayoutIcon layout={value} />}
            items={options.map(makeOption)}
            onSelect={onChange}
        />
    );
}

function makeOption(type: ViewLayout) {
    return {
        key: type,
        value: type,
        element: <LayoutIcon layout={type} />
    }
}

function LayoutIcon({ layout }: { layout: ViewLayout }) {
    switch (layout) {
        case ViewLayout.List: 
            return <IconButton icon={faThList} />
        case ViewLayout.Boxes:
            return <IconButton icon={faTh} />
    }
}