import { GenerationMark } from "../types";
import { Dropdown } from "./Dropdown";
import { GenerationMarkIcon } from "./GenerationMark";
import { EmptyGenerationMarkIcon } from "./EmptyGenerationMark";

interface GenerationMarkSelectProps {
    value: GenerationMark | undefined
    onChange: (value: GenerationMark | undefined) => void
}

export function GenerationMarkSelect({ value, onChange }: GenerationMarkSelectProps) {
    return (
        <Dropdown
            trigger={
                value 
                    ? <GenerationMarkIcon mark={value} /> 
                    : <EmptyGenerationMarkIcon />
                }
            items={[
                value, 
                ...ALL_GENERATION_MARKS.filter(b => b !== value)
            ].map(makeGenerationMarkOption)}
            onSelect={onChange}
        />
    );
}

const ALL_GENERATION_MARKS = [
    undefined,
    GenerationMark.Arceus,
    GenerationMark.Triangle,
    GenerationMark.Galar,
    GenerationMark.LetsGo,
    GenerationMark.Go,
    GenerationMark.Clover,
    GenerationMark.Pentagon,
    GenerationMark.Gameboy,
    GenerationMark.NoMark,
];

function makeGenerationMarkOption(mark: GenerationMark | undefined) {
    if (mark === undefined) {
        return {
            key: "none",
            value: mark,
            element: <EmptyGenerationMarkIcon />
        };
    } else {
        return {
            key: mark.toString(),
            value: mark,
            element: <GenerationMarkIcon mark={mark} />
        }
    }
}