import styled from "styled-components";
import { Gender } from "../types";
import { GenderIcon } from "./GenderIcon";

interface PokemonLabelProps {
    name: string
    form: string | undefined
    genderForm: Gender | undefined
    infoUrl: string
}

export function PokemonLabel({ name, form, genderForm, infoUrl }: PokemonLabelProps) {
    return (
        <Label>
            <div>
                <Link href={infoUrl} target="_blank">{name}</Link> 
                {" "}
                {genderForm && <GenderIcon gender={genderForm} />}
            </div>
            <div><FormLabel>{form}</FormLabel></div>
        </Label>
    );
}

const Label = styled.div`
    text-align: left;
`;

const FormLabel = styled.span`
    color: #aeaeae;
    font-size: 12px;
`;

const Link = styled.a`
    color: black;

    &:hover {
        color: black;
        text-decoration: underline;
    }
`;