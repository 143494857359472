export function downloadFile(filename: string, text: string): void {
    const data = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
    const element = document.createElement('a');
    element.setAttribute('href', data);
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}