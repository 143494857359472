import { BallType } from "../types";
import { BallIcon } from "./BallIcon";
import { EmptyBallIcon } from "./EmptyBallIcon";
import { Dropdown } from "./Dropdown";
import { NoBallIcon } from "./NoBallIcon";

interface BallTypeSelectProps {
    value: BallType | undefined | null
    onChange: (value: BallType | undefined | null) => void,
    includeEmpty?: boolean
}

export function BallTypeSelect({ 
    value, 
    onChange, 
    includeEmpty = false 
}: BallTypeSelectProps) {
    const options = [value];
    if (includeEmpty && value !== undefined) {
        options.push(undefined);
    } 
    options.push(...ALL_BALLS.filter(b => b !== value));
    return (
        <Dropdown
            trigger={
                value 
                    ? <BallIcon type={value} /> 
                    : value === null && includeEmpty 
                        ? <NoBallIcon /> 
                        : <EmptyBallIcon />
            }
            items={options.map((v) => makeBallOption(v, includeEmpty))}
            onSelect={onChange}
        />
    );
}

const ALL_BALLS = [
    null,
    BallType.PokeBall,
    BallType.PremierBall,
    BallType.GreatBall,
    BallType.UltraBall,
    BallType.MasterBall,
    BallType.LuxuryBall,
    BallType.CherishBall,
    BallType.DiveBall,
    BallType.DreamBall,
    BallType.DuskBall,
    BallType.FastBall,
    BallType.FriendBall,
    BallType.HealBall,
    BallType.HeavyBall,
    BallType.LevelBall,
    BallType.LoveBall,
    BallType.LureBall,
    BallType.MoonBall,
    BallType.NestBall,
    BallType.NetBall,
    BallType.ParkBall,
    BallType.QuickBall,
    BallType.RepeatBall,
    BallType.SafariBall,
    BallType.SportBall,
    BallType.TimerBall,
    BallType.BeastBall,
    BallType.HisuianPokeBall,
    BallType.HisuianGreatBall,
    BallType.HisuianUltraBall,
    BallType.HisuianHeavyBall,
    BallType.LeadenBall,
    BallType.GigatonBall,
    BallType.FeatherBall,
    BallType.WingBall,
    BallType.JetBall,
    BallType.OriginBall,
    BallType.StrangeBall,
];

function makeBallOption(type: BallType | null | undefined, includeEmpty: boolean) {
    if (type === null) {
        return {
            key: "none",
            value: null,
            element: includeEmpty ? <NoBallIcon /> : <EmptyBallIcon />
        }
    } else if (type === undefined) {
        return {
            key: "empty",
            value: undefined,
            element: <EmptyBallIcon />
        }
    } else {
        return {
            key: type.toString(),
            value: type,
            element: <BallIcon type={type} />
        };
    }
}