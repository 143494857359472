import { BoxEntry } from "../types";
import { BoxEntryBox } from "./BoxEntryBox";
import { IconButton } from "./IconButton";
import { faChevronLeft, faChevronRight, faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { useSearchParam } from "../hooks";
import styled from "styled-components";

interface BoxEntryBoxListProps {
    boxEntries: BoxEntry[]
    setBoxEntry: (id: string, boxEntry: BoxEntry) => void
}

export function BoxEntryBoxList({ boxEntries, setBoxEntry }: BoxEntryBoxListProps) {
    const [boxNumberParam, setBoxNumberParam] = useSearchParam("box");
    
    const boxNumber = parseInt(boxNumberParam || "1");
    function setBoxNumber(boxNumber: number) {
        setBoxNumberParam(boxNumber.toString());
    }

    const boxEntriesWithSlots = boxEntries.filter(e => e.needsSlot);

    const boxes: BoxEntry[][] = [];
    for (let index = 0; index < boxEntriesWithSlots.length; index += 30) {
        boxes.push(boxEntriesWithSlots.slice(index, index + 30));
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                        <IconButton 
                            icon={faStepBackward} 
                            onClick={() => setBoxNumber(0)}
                            disabled={boxNumber <= 0}
                        />
                        <IconButton 
                            icon={faChevronLeft} 
                            onClick={() => setBoxNumber(boxNumber - 1)}
                            disabled={boxNumber <= 0}
                        />
                    </div>
                    <Label>Box {boxNumber + 1}</Label>
                    <div>
                        <IconButton 
                            icon={faChevronRight} 
                            onClick={() => setBoxNumber(boxNumber + 1)}
                            disabled={boxNumber >= boxes.length - 1}
                        />
                        <IconButton 
                            icon={faStepForward} 
                            onClick={() => setBoxNumber(boxes.length - 1)}
                            disabled={boxNumber >= boxes.length - 1}
                        />
                    </div>
                </div>
                <BoxEntryBox 
                    boxEntries={boxes[boxNumber]} 
                    setBoxEntry={setBoxEntry} 
                />
            </div>
        </div>
    );
}

const Label = styled.span`
  box-sizing: border-box;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid;
  border-radius: 6px;
  appearance: none;
  color: #24292e;
  background-color: #fafbfc;
  border-color: rgba(27,31,35,0.15);
  box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
  transition: .2s cubic-bezier(.3,0,.5,1);
  transition-property: color,background-color,border-color;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  height: 28px;
`;