import styled from "styled-components";
import emptyBallIcon from "../assets/img/balls/emptyball.png";

export function EmptyBallIcon() {
    return <Icon src={emptyBallIcon} alt="Not yet obtained" />;
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
    opacity: 50%;
`;