import { useState } from "react";
import styled from "styled-components";
import debounce from 'lodash/debounce';

interface InlineInputProps {
    value: string,
    onChange: (value: string) => void
}

export function InlineInput({ value, onChange }: InlineInputProps) {
    const [innerValue, setInnerValue] = useState(value);

    const debouncedOnChange = debounce(onChange, 1000);

    const change = (e: any) => {
        setInnerValue(e.target.value);
        debouncedOnChange(e.target.value);
    };

    return <Input value={innerValue} onChange={change} />;
}

const Input = styled.input`
    width: 100%;
    border: none;
    border-radius: 5px;
    height: 30px;
    padding: 8px;

    &:hover {
        background-color: #f6f6f6;
    }

    &:active, &:focus {
        outline: none;
        background-color: #f6f6f6;
    }
`;