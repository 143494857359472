import { Link } from "react-router-dom";
import styled from "styled-components";
import { BoxEntry } from "../types";
import { PokemonImage } from "./PokemonImage";

interface BoxEntryBoxProps {
    boxEntries: BoxEntry[]
    setBoxEntry: (id: string, boxEntry: BoxEntry) => void
}

export function BoxEntryBox({ boxEntries, setBoxEntry }: BoxEntryBoxProps) {
    const rows = [];
    for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
        const row = []
        for (let columnIndex = 0; columnIndex < 6; columnIndex++) {
            const index = rowIndex * 6 + columnIndex;
            row.push(boxEntries[index])
        }
        rows.push(row);
    }


    return (
        <Wrapper>
            {rows.map((row, rowIndex) => (
                <BoxRow key={rowIndex}>
                    {row.map((boxEntry, columnIndex) => (
                        boxEntry 
                            ? <Link key={columnIndex} to={`/entry/${boxEntry.id}`}>
                                <BoxCell>
                                    <PokemonImage 
                                        url={boxEntry.iconUrl} 
                                        silhouette={
                                            boxEntry.needsSlot 
                                            && boxEntry.ball === null
                                        }
                                        size="medium"
                                    />
                                </BoxCell>
                            </Link>
                            : <BoxCell>
                                <div style={{ opacity: 0 }}> 
                                    <PokemonImage 
                                        url={boxEntries[0].iconUrl} 
                                        silhouette={true}
                                        size="medium"
                                    />
                                </div>
                            </BoxCell>
                    ))}
                </BoxRow>
            ))}
        </Wrapper>
    );
}

const BoxCell = styled.div`
    background-color: #fafafa;
    border-radius: 8px;
    padding: 5px;
    transition: .2s cubic-bezier(.3,0,.5,1);
    transition-property: color,background-color,border-color;

    &:hover {
        background-color: #eff0f2;

        img {
            -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.075));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.075));
        }
    }

    margin: 5px;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;


const Wrapper = styled.div`
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(27,31,35,0.15);
    box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
    margin-bottom: 10px;
`;