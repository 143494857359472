import { useParams } from "react-router";
import styled from "styled-components";
import { BoxEntry } from "../../types";
import { BallTypeSelect } from "../BallTypeSelect";
import { GenerationMarkSelect } from "../GenerationMarkSelect";
import { InlineInput } from "../InlineInput";
import { LanguageSelect } from "../LanguageSelect";
import { Page } from "../Layout/Page";
import { PokemonImage } from "../PokemonImage";
import { PokemonLabel } from "../PokemonLabel";

interface BoxEntryPageProps {
    boxEntry: BoxEntry,
    onChange: (boxEntry: BoxEntry) => void
}

function BoxEntryPage({ boxEntry, onChange }: BoxEntryPageProps) {
    return (
        <Page>
            <Box>
                <InfoRow>
                    <span>#{boxEntry.nationalPokedexNumber}</span>
                    { boxEntry.needsSlot && <BallTypeSelect 
                        value={boxEntry.ball} 
                        onChange={(ball) => onChange({ ...boxEntry, ball })}
                    /> }
                    <PokemonLabel 
                        name={boxEntry.name} 
                        form={boxEntry.form} 
                        genderForm={boxEntry.genderForm} 
                        infoUrl={boxEntry.infoUrl}
                    />
                    { boxEntry.needsSlot && <LanguageSelect 
                        value={boxEntry.language} 
                        onChange={(language) => onChange({ ...boxEntry, language })}
                    /> }
                    { boxEntry.needsSlot && <GenerationMarkSelect 
                        value={boxEntry.generationMark} 
                        onChange={(generationMark) => onChange({ 
                            ...boxEntry, 
                            generationMark 
                        })}
                    /> }
                </InfoRow>
                <div>
                    <PokemonImage 
                        url={boxEntry.iconUrl} 
                        silhouette={boxEntry.ball === null && boxEntry.needsSlot}
                        size="medium"
                    />
                </div>
                <InfoRow >
                    <span>Original Trainer</span> 
                    { boxEntry.needsSlot && <InlineInput
                        value={boxEntry.originalTrainer}
                        onChange={(originalTrainer) => onChange({ 
                            ...boxEntry, 
                            originalTrainer 
                        })}
                    /> }
                </InfoRow>
                <InfoRow >
                    <span>Trainer ID:</span> 
                    <div style={{ width: "40px"}}>
                    { boxEntry.needsSlot && <InlineInput
                        value={boxEntry.trainerId}
                        onChange={(trainerId) => onChange({ 
                            ...boxEntry, 
                            trainerId 
                        })}
                    /> }</div>
                </InfoRow>
                <InfoRow >
                    <span>Notes</span> 
                    <InlineInput
                        value={boxEntry.notes}
                        onChange={(notes) => onChange({ 
                            ...boxEntry, 
                            notes 
                        })}
                    />
                </InfoRow>
            </Box>
        </Page>
    );
}

const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const Box = styled.div`
    width: 100%;
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(27,31,35,0.15);
    box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
    padding: 10px;
`;

interface ConnectedBoxEntryPageProps {
    boxEntries: { [id: string]: BoxEntry },
    setBoxEntry: (id: string, boxEntry: BoxEntry) => void
}

export function ConnectedBoxEntryPage({ 
    boxEntries,
    setBoxEntry
 }: ConnectedBoxEntryPageProps) {
    let { id } = useParams<{id: string}>();

    const boxEntry = boxEntries[id];

    return <BoxEntryPage 
        boxEntry={boxEntry} 
        onChange={(entry) => setBoxEntry(id, entry)} 
    />
}