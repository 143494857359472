export enum BallType {
    BeastBall = "Beast Ball",
    CherishBall = "Cherish Ball",
    DiveBall = "Dive Ball",
    DreamBall = "Dream Ball",
    DuskBall = "Dusk Ball",
    FastBall = "Fast Ball",
    FriendBall = "Friend Ball",
    GreatBall = "Great Ball",
    HealBall = "Heal Ball",
    HeavyBall = "Heavy Ball",
    LevelBall = "Level Ball",
    LoveBall = "Love Ball",
    LureBall = "Lure Ball",
    LuxuryBall = "Luxury Ball",
    MasterBall = "Master Ball",
    MoonBall = "Moon Ball",
    NestBall = "Nest Ball",
    NetBall = "Net Ball",
    ParkBall = "Park Ball",
    PokeBall = "Poké Ball",
    PremierBall = "Premier Ball",
    QuickBall = "Quick Ball",
    RepeatBall = "Repeat Ball",
    SafariBall = "Safari Ball",
    SportBall = "Sport Ball",
    TimerBall = "Timer Ball",
    UltraBall = "Ultra Ball",
    HisuianPokeBall = "Hisuian Poké Ball",
    HisuianGreatBall = "Hisuian Great Ball",
    HisuianUltraBall = "Hisuian Ultra Ball",
    HisuianHeavyBall = "Hisuian Heavy Ball",
    LeadenBall = "Leaden Ball",
    GigatonBall = "Gigaton Ball",
    FeatherBall = "Feather Ball",
    WingBall = "Wing Ball",
    JetBall = "Jet Ball",
    OriginBall = "Origin Ball",
    StrangeBall = "Strange Ball",
}

export enum Language {
    ENG = "ENG",
    JPN = "JPN",
    CHS = "CHS",
    FRE = "FRE",
    SPA = "SPA",
    CHT = "CHT",
    GER = "GER",
    KOR = "KOR"
}

export enum GenerationMark {
    Pentagon = "Pentagon",
    Galar = "Galar",
    Go = "Go",
    Clover = "Clover",
    Gameboy = "Gameboy",
    LetsGo = "Let's Go",
    Arceus = "Arceus",
    Triangle = "Triangle",
    NoMark = "No Mark",
}

export enum Gender {
    Male = "M",
    Female = "F"
}

export interface BoxEntry {
    nationalPokedexNumber: number,
    name: string,
    iconUrl: string,
    infoUrl: string,
    genderForm: Gender | undefined,
    form: string | undefined,
    inHome: boolean,
    ball: BallType | undefined | null,
    language: Language | undefined,
    generationMark: GenerationMark | undefined,
    originalTrainer: string,
    trainerId: string,
    notes: string,
    slot: number,
    id: string,
    obtainable: boolean,
    needsSlot: boolean
}