import React from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";
import 'react-virtualized/styles.css'; 
import 'semantic-ui-css/semantic.min.css';
import { MainContent } from './components/MainContent';
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div style={{padding: "20px"}}>
        <MainContent />
      </div>
    </QueryClientProvider>
  );
}

export default App;
