import { BoxEntryTable } from "../BoxEntryTable";
import styled from "styled-components";
import { BallType, BoxEntry, GenerationMark, Language, ViewLayout } from "../../types";
import { downloadFile } from "../../utils";
import { LoadFileButton } from "../LoadFileButton";
import { BallTypeSelect } from "../BallTypeSelect";
import { GenerationMarkSelect } from "../GenerationMarkSelect";
import { LanguageSelect } from "../LanguageSelect";
import { Page } from "../Layout/Page";
import { useSearchParam } from "../../hooks";
import { BoxEntryBoxList } from "../BoxEntryBoxList";
import { ViewLayoutSelect } from "../ViewLayoutSelect";


interface ConnectedListPageProps {
  boxEntries: { [id: string]: BoxEntry },
  setBoxEntries: (boxEntries: { [id: string]: BoxEntry }) => void,
  setBoxEntry: (id: string, boxEntry: BoxEntry) => void
}

export function ConnectedListPage({ 
  boxEntries, 
  setBoxEntries, 
  setBoxEntry 
}: ConnectedListPageProps) {
  const [searchParam, setSearchParam] = useSearchParam("search");
  const [ballFilterParam, setBallFilterParam] = useSearchParam("ball");
  const [languageFilterParam, setLanguageFilterParam] = useSearchParam("language");
  const [
    generationMarkFilterParam, 
    setGenerationMarkFilterParam
  ] = useSearchParam("generation");
  const [viewLayoutParam, setViewLayoutParam] = useSearchParam("layout");

  const ballFilter = ballFilterParam === "null" ? null : ballFilterParam as BallType;
  function setBallFilter(ballFilter: BallType | null | undefined) {
    const stringValue = ballFilter === null ? "null" : ballFilter?.toString();
    setBallFilterParam(stringValue);
  }

  const languageFilter = languageFilterParam as Language;
  function setLanguageFilter(languageFilter: Language | undefined) {
    const stringValue = languageFilter?.toString();
    setLanguageFilterParam(stringValue);
  }

  const generationMarkFilter = generationMarkFilterParam as GenerationMark;
  function setGenerationMarkFilter(generationMarkFilter: GenerationMark | undefined) {
    const stringValue = generationMarkFilter?.toString();
    setGenerationMarkFilterParam(stringValue);
  }

  const search = searchParam || "";
  function setSearch(search: string) {
    const stringValue = search === "" ? undefined : search;
    setSearchParam(stringValue);
  }

  const viewLayout = viewLayoutParam as ViewLayout || ViewLayout.List;
  function setViewLayout(viewLayout: ViewLayout) {
    setViewLayoutParam(viewLayout.toString());
  }

  return <ListPage
    boxEntries={boxEntries}
    setBoxEntries={setBoxEntries}
    setBoxEntry={setBoxEntry}
    ballFilter={ballFilter}
    setBallFilter={setBallFilter}
    languageFilter={languageFilter}
    setLanguageFilter={setLanguageFilter}
    generationMarkFilter={generationMarkFilter}
    setGenerationMarkFilter={setGenerationMarkFilter}
    search={search}
    setSearch={setSearch}
    viewLayout={viewLayout}
    setViewLayout={setViewLayout}
  />
}

interface ListPageProps extends ConnectedListPageProps {
  ballFilter: BallType | null | undefined,
  setBallFilter: (ballFilter: BallType | null | undefined) => void
  languageFilter: Language | undefined,
  setLanguageFilter: (languageFilter: Language | undefined) => void
  generationMarkFilter: GenerationMark | undefined,
  setGenerationMarkFilter: (languageFilter: GenerationMark | undefined) => void
  search: string,
  setSearch: (search: string) => void
  viewLayout: ViewLayout
  setViewLayout: (viewLayout: ViewLayout) => void
}

export function ListPage({ 
  boxEntries, 
  setBoxEntries, 
  setBoxEntry,
  ballFilter,
  setBallFilter,
  languageFilter,
  setLanguageFilter,
  generationMarkFilter,
  setGenerationMarkFilter,
  search,
  setSearch,
  viewLayout,
  setViewLayout
}: ListPageProps) {
  const filteredEntries = Object.values(boxEntries)
    .filter(boxEntryMatchesSearch(
      search, 
      ballFilter,
      languageFilter,
      generationMarkFilter
    ));

  function downloadBoxEntries() {
    const text = JSON.stringify(boxEntries, undefined, 1);
    downloadFile("pokemon-box-entries.json", text);
  }

  function loadFile(text: string) {
    const newBoxEntries = JSON.parse(text);
    setBoxEntries(newBoxEntries);
  }

  return (
    <Page>
      <Toolbar>
        <Input 
          type="text" 
          value={search} 
          onChange={(e) => setSearch(e.target.value)} 
          placeholder="Search Pokémon"
          style={{ 
            marginBottom: "10px"
          }}
        />
        <div style={{  marginLeft: "4px" }} >
          <ViewLayoutSelect value={viewLayout} onChange={setViewLayout} />
        </div>
        <div style={{  marginLeft: "4px" }} >
          <BallTypeSelect value={ballFilter} onChange={setBallFilter} includeEmpty />
        </div>
        <div style={{  marginLeft: "4px", padding: "3px" }} >
          <LanguageSelect value={languageFilter} onChange={setLanguageFilter} />
        </div>
        <div style={{  marginRight: "6px", padding: "4px" }} >
          <GenerationMarkSelect 
            value={generationMarkFilter} 
            onChange={setGenerationMarkFilter} 
          />
        </div>
        <Button onClick={downloadBoxEntries} style={{ marginRight: "10px" }} >
          Download
        </Button>
        <LoadFileButton onLoadFile={loadFile} />
      </Toolbar>
      { 
        viewLayout === ViewLayout.Boxes 
          ? <BoxEntryBoxList 
            boxEntries={Object.values(boxEntries)} 
            setBoxEntry={setBoxEntry} 
          />
          : <BoxEntryTable 
            boxEntries={filteredEntries || []} 
            setBoxEntry={setBoxEntry} 
          /> 
      }
    </Page>
  );
}

const Input = styled.input`
  box-sizing: border-box;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid;
  border-radius: 6px;
  appearance: none;
  color: #24292e;
  background-color: #fafbfc;
  border-color: rgba(27,31,35,0.15);
  box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
  transition: .2s cubic-bezier(.3,0,.5,1);
  transition-property: color,background-color,border-color;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  height: 28px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  box-sizing: border-box;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid;
  border-radius: 6px;
  appearance: none;
  color: #24292e;
  background-color: #fafbfc;
  border-color: rgba(27,31,35,0.15);
  box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
  transition: .2s cubic-bezier(.3,0,.5,1);
  transition-property: color,background-color,border-color;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  height: 28px;

  &:hover {
    background-color: #ededed;
  }

  &:focus, &:active {
    outline: none;
  }
`;

function boxEntryMatchesSearch(
  search: string, 
  ballFilter: BallType | undefined | null,
  languageFilter: Language | undefined,
  generationMarkFilter: GenerationMark | undefined
) {
  return function doesMatch(boxEntry: BoxEntry) {
    const searchTexts = [
      boxEntry.name, 
      boxEntry.form, 
      boxEntry.notes, 
      boxEntry.originalTrainer
    ];
    const matchesSearch = searchTexts.some(searchText => {
      return searchText && searchText.toLowerCase().includes(search.toLowerCase());
    });

    const matchesBall = ballFilter !== undefined 
      ? ballFilter === null 
        ? boxEntry.ball === null && boxEntry.needsSlot 
        : boxEntry.ball === ballFilter 
      : true;
    const matchesLanguage = languageFilter !== undefined 
      ? boxEntry.language === languageFilter 
      : true;
    const matchesGenerationMark = generationMarkFilter !== undefined 
      ? boxEntry.generationMark === generationMarkFilter 
      : true;

    const isObtainable = boxEntry.obtainable;

    return (
      isObtainable 
      && matchesSearch 
      && matchesBall 
      && matchesLanguage 
      && matchesGenerationMark
    );
  }
}