import styled from "styled-components";
import { Language } from "../types";

interface LanguageIconProps {
    language: Language
}

export function LanguageIcon({ language }: LanguageIconProps) {
    return <Icon>{language}</Icon>;
}

const Icon = styled.div`
    background-color: #43484e;
    border-radius: 4px;
    color: white;
    padding: 0px;
    margin: 2px;
    font-size: 10px;
    font-weight: bold;
    width: 30px;
    text-align: center;
`;