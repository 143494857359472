import styled from "styled-components";
import { BallType } from "../types";
import beastBallIcon from "../assets/img/balls/beastball.png";
import cherishBallIcon from "../assets/img/balls/cherishball.png";
import diveBallIcon from "../assets/img/balls/diveball.png";
import dreamBallIcon from "../assets/img/balls/dreamball.png";
import duskBallIcon from "../assets/img/balls/duskball.png";
import fastBallIcon from "../assets/img/balls/fastball.png";
import friendBallIcon from "../assets/img/balls/friendball.png";
import greatBallIcon from "../assets/img/balls/greatball.png";
import healBallIcon from "../assets/img/balls/healball.png";
import heavyBallIcon from "../assets/img/balls/heavyball.png";
import levelBallIcon from "../assets/img/balls/levelball.png";
import loveBallIcon from "../assets/img/balls/loveball.png";
import lureBallIcon from "../assets/img/balls/lureball.png";
import luxuryBallIcon from "../assets/img/balls/luxuryball.png";
import masterBallIcon from "../assets/img/balls/masterball.png";
import moonBallIcon from "../assets/img/balls/moonball.png";
import nestBallIcon from "../assets/img/balls/nestball.png";
import netBallIcon from "../assets/img/balls/netball.png";
import parkBallIcon from "../assets/img/balls/parkball.png";
import pokeBallIcon from "../assets/img/balls/pokeball.png";
import premierBallIcon from "../assets/img/balls/premierball.png";
import quickBallIcon from "../assets/img/balls/quickball.png";
import repeatBallIcon from "../assets/img/balls/repeatball.png";
import safariBallIcon from "../assets/img/balls/safariball.png";
import sportBallIcon from "../assets/img/balls/sportball.png";
import timerBallIcon from "../assets/img/balls/timerball.png";
import ultraBallIcon from "../assets/img/balls/ultraball.png";
import hisuianPokeBallIcon from "../assets/img/balls/pokeballlegends.png"
import hisuianGreatBallIcon from "../assets/img/balls/greatballlegends.png"
import hisuianUltraBallIcon from "../assets/img/balls/ultraballlegends.png"
import hisuianHeavyBallIcon from "../assets/img/balls/heavyballlegends.png"
import leadenBallIcon from "../assets/img/balls/leadenball.png"
import gigatonBallIcon from "../assets/img/balls/gigatonball.png"
import featherBallIcon from "../assets/img/balls/featherball.png"
import wingBallIcon from "../assets/img/balls/wingball.png"
import jetBallIcon from "../assets/img/balls/jetball.png"
import originBallIcon from "../assets/img/balls/originball.png"
import strangeBallIcon from "../assets/img/balls/strangeball.png"

import { EmptyBallIcon } from "./EmptyBallIcon";


interface BallIconProps {
    type: BallType
}

export function BallIcon({ type }: BallIconProps) {
    switch (type) {
        case BallType.BeastBall:
            return <Icon src={beastBallIcon} alt="Beast Ball" />
        case BallType.CherishBall:
            return <Icon src={cherishBallIcon} alt="Cherish Ball" />
        case BallType.DiveBall:
            return <Icon src={diveBallIcon} alt="Dive Ball" />
        case BallType.DreamBall:
            return <Icon src={dreamBallIcon} alt="Dream Ball" />
        case BallType.DuskBall:
            return <Icon src={duskBallIcon} alt="Dusk Ball" />
        case BallType.FastBall:
            return <Icon src={fastBallIcon} alt="Fast Ball" />
        case BallType.FriendBall:
            return <Icon src={friendBallIcon} alt="Friend Ball" />
        case BallType.GreatBall:
            return <Icon src={greatBallIcon} alt="Great Ball" />
        case BallType.HealBall:
            return <Icon src={healBallIcon} alt="Heal Ball" />
        case BallType.HeavyBall:
            return <Icon src={heavyBallIcon} alt="Heavy Ball" />
        case BallType.LevelBall:
            return <Icon src={levelBallIcon} alt="Level Ball" />
        case BallType.LoveBall:
            return <Icon src={loveBallIcon} alt="Love Ball" />
        case BallType.LureBall:
            return <Icon src={lureBallIcon} alt="Lure Ball" />
        case BallType.LuxuryBall:
            return <Icon src={luxuryBallIcon} alt="Luxury Ball" />
        case BallType.MasterBall:
            return <Icon src={masterBallIcon} alt="Master Ball" />
        case BallType.MoonBall:
            return <Icon src={moonBallIcon} alt="Moon Ball" />
        case BallType.NestBall:
            return <Icon src={nestBallIcon} alt="Nest Ball" />
        case BallType.NetBall:
            return <Icon src={netBallIcon} alt="Net Ball" />
        case BallType.ParkBall:
            return <Icon src={parkBallIcon} alt="Park Ball" />
        case BallType.PokeBall:
            return <Icon src={pokeBallIcon} alt="Poke Ball" />
        case BallType.PremierBall:
            return <Icon src={premierBallIcon} alt="Premier Ball" />
        case BallType.QuickBall:
            return <Icon src={quickBallIcon} alt="Quick Ball" />
        case BallType.RepeatBall:
            return <Icon src={repeatBallIcon} alt="Repeat Ball" />
        case BallType.SafariBall:
            return <Icon src={safariBallIcon} alt="Safari Ball" />
        case BallType.SportBall:
            return <Icon src={sportBallIcon} alt="Sport Ball" />
        case BallType.TimerBall:
            return <Icon src={timerBallIcon} alt="Timer Ball" />
        case BallType.UltraBall:
            return <Icon src={ultraBallIcon} alt="Ultra Ball" />
        case BallType.HisuianPokeBall:
            return <Icon src={hisuianPokeBallIcon} alt="Hisuian Poké Ball" />
        case BallType.HisuianGreatBall:
            return <Icon src={hisuianGreatBallIcon} alt="Hisuian Great Ball" />
        case BallType.HisuianUltraBall:
            return <Icon src={hisuianUltraBallIcon} alt="Hisuian Ultra Ball" />
        case BallType.HisuianHeavyBall:
            return <Icon src={hisuianHeavyBallIcon} alt="Hisuian Heavy Ball" />
        case BallType.LeadenBall:
            return <Icon src={leadenBallIcon} alt="Leaden Ball" />
        case BallType.GigatonBall:
            return <Icon src={gigatonBallIcon} alt="Gigaton Ball" />
        case BallType.FeatherBall:
            return <Icon src={featherBallIcon} alt="Feather Ball" />
        case BallType.WingBall:
            return <Icon src={wingBallIcon} alt="Wing Ball" />
        case BallType.JetBall:
            return <Icon src={jetBallIcon} alt="Jet Ball" />
        case BallType.OriginBall:
            return <Icon src={originBallIcon} alt="Origin Ball" />
        case BallType.StrangeBall:
            return <Icon src={strangeBallIcon} alt="Strange Ball" />
        default:
            return <EmptyBallIcon />
    }
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`;