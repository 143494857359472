import styled from "styled-components";
import { GenerationMark } from "../types";
import cloverIcon from "../assets/img/generation_marks/clover.png"
import galarIcon from "../assets/img/generation_marks/galar.png"
import gameboyIcon from "../assets/img/generation_marks/gameboy.png"
import goIcon from "../assets/img/generation_marks/go.png"
import letsGoIcon from "../assets/img/generation_marks/lets_go.png"
import pentagonIcon from "../assets/img/generation_marks/pentagon.png"
import noMarkIcon from "../assets/img/generation_marks/no_mark.png"
import triangleIcon from "../assets/img/generation_marks/triangle.png"
import arceusIcon from "../assets/img/generation_marks/arceus.png"

interface GenerationMarkProps {
    mark: GenerationMark
}

export function GenerationMarkIcon({ mark }: GenerationMarkProps) {
    switch (mark) {
        case GenerationMark.Clover:
            return <Icon src={cloverIcon} alt="Clover" />
        case GenerationMark.Galar:
            return <Icon src={galarIcon} alt="Galar" />
        case GenerationMark.Gameboy:
            return <Icon src={gameboyIcon} alt="Gameboy" />
        case GenerationMark.Go:
            return <Icon src={goIcon} alt="Go" />
        case GenerationMark.LetsGo:
            return <Icon src={letsGoIcon} alt="Let's Go" />
        case GenerationMark.Pentagon:
            return <Icon src={pentagonIcon} alt="Pentagon" />
        case GenerationMark.Triangle:
            return <Icon src={triangleIcon} alt="Triangle" />
        case GenerationMark.Arceus:
            return <Icon src={arceusIcon} alt="Arceus" />
        case GenerationMark.NoMark:
            return <Icon src={noMarkIcon} alt="No Mark" />
        default:
            return <div>???</div>
    }
}

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;