import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

interface IconButtonProps {
    icon: IconDefinition,
    onClick?: () => void,
    disabled?: boolean
}

export function IconButton({ icon, onClick, disabled = false }: IconButtonProps) {
    return (
        <Button onClick={onClick} disabled={disabled} >
            <Icon icon={icon} />
        </Button>
    );
}

const Button = styled.button`
    color: gray;
    height: 30px;
    padding: 6px 10px;
    border-radius: 8px;
    user-select: none;
    border: none;
    background-color: transparent;
    transition: .2s cubic-bezier(.3,0,.5,1);
    transition-property: color,background-color,border-color;

    &[disabled] {
        color: lightgray;
    }

    &:not([disabled]) {
        cursor: pointer;
    }

    &:hover&:not([disabled]) {
        color: #3a3a3a;
        background-color: #eeeeee;
    }

    &:active, &:focus {
        outline: none;
    }
`;