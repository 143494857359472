import styled from "styled-components";
import noBallIcon from "../assets/img/balls/noball.png";

export function NoBallIcon() {
    return <Icon src={noBallIcon} alt="Not yet obtained" />;
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`;