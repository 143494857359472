import styled from "styled-components";
import { BoxEntry } from "../types";
import { BallTypeSelect } from "./BallTypeSelect";
import { GenerationMarkSelect } from "./GenerationMarkSelect";
import { InlineInput } from "./InlineInput";
import { LanguageSelect } from "./LanguageSelect";
import { PokemonImage } from "./PokemonImage";
import { PokemonLabel } from "./PokemonLabel";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from "./IconButton";
import { useHistory } from "react-router";

interface BoxEntryRowProps {
    boxEntry: BoxEntry,
    isLastInGroup: boolean,
    style: React.CSSProperties,
    onChange: (boxEntry: BoxEntry) => void
}

export function BoxEntryRow({ 
    boxEntry, 
    isLastInGroup, 
    style, 
    onChange 
}: BoxEntryRowProps) {
    const history = useHistory();

    return (
        <Row className={isLastInGroup ? "last-in-group" : ""} style={style}>
            <Cell style={{ width: "40px" }}>
                { boxEntry.needsSlot && <BallTypeSelect 
                    value={boxEntry.ball} 
                    onChange={(ball) => onChange({ ...boxEntry, ball })}
                /> }
            </Cell>
            <Cell style={{ width: "40px" }}>#{boxEntry.nationalPokedexNumber}</Cell>
            <Cell style={{ width: "60px" }}>
                <PokemonImage 
                    url={boxEntry.iconUrl} 
                    silhouette={boxEntry.ball === null && boxEntry.needsSlot}
                />
            </Cell>
            <Cell style={{ width: "160px" }}>
                <PokemonLabel 
                    name={boxEntry.name} 
                    form={boxEntry.form} 
                    genderForm={boxEntry.genderForm} 
                    infoUrl={boxEntry.infoUrl}
                />
            </Cell>
            <Cell style={{ width: "50px" }}>
                { boxEntry.needsSlot && <LanguageSelect 
                    value={boxEntry.language} 
                    onChange={(language) => onChange({ ...boxEntry, language })}
                /> }
            </Cell>
            <Cell style={{ width: "40px" }}>
                { boxEntry.needsSlot && <GenerationMarkSelect 
                    value={boxEntry.generationMark} 
                    onChange={(generationMark) => onChange({ 
                        ...boxEntry, 
                        generationMark 
                    })}
                /> }
            </Cell>
            <Cell style={{ width: "150px" }}>
                { boxEntry.needsSlot && <InlineInput
                    value={boxEntry.originalTrainer}
                    onChange={(originalTrainer) => onChange({ 
                        ...boxEntry, 
                        originalTrainer 
                    })}
                /> }
            </Cell>
            <Cell style={{ width: "90px" }}>
                { boxEntry.needsSlot && <InlineInput
                    value={boxEntry.trainerId}
                    onChange={(trainerId) => onChange({ 
                        ...boxEntry, 
                        trainerId 
                    })}
                /> }
            </Cell>
            <Cell style={{ flexGrow: 1 }}>
                <InlineInput
                    value={boxEntry.notes}
                    onChange={(notes) => onChange({ 
                        ...boxEntry, 
                        notes 
                    })}
                />
            </Cell>
            <Cell style={{ width: "50px" }}>
                <IconButton 
                    icon={faChevronRight} 
                    onClick={() => history.push(`/entry/${boxEntry.id}`)} 
                />
            </Cell>
        </Row>
    );
}

const Cell = styled.div`
    padding: 5px 10px;
    text-align: center;
    display:flex;
    align-items:center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    height: 54px;
    width: 100%;

    &.last-in-group {
        border-bottom: 1px solid #e1e4e8;
    }
`;