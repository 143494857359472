import { useState } from "react";
import { BoxEntry } from "../../types";
import debounce from 'lodash/debounce';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConnectedBoxEntryPage } from "../BoxEntryPage";
import { ConnectedListPage } from "../ListPage";

function getLocalBoxEntries(): { [id: string]: BoxEntry } {
  return JSON.parse(localStorage.getItem("box-entries") || "{}") || [];
}

function setLocalBoxEntries(boxEntries: { [id: string]: BoxEntry }) {
  localStorage.setItem("box-entries", JSON.stringify(boxEntries));
}

const debouncedSetLocalBoxEntries = debounce(setLocalBoxEntries, 1000);

export function MainContent() {
  const [boxEntries, setBoxEntries] = useState(() => getLocalBoxEntries());

  function setBoxEntry(id: string, boxEntry: BoxEntry) {
    const newBoxEntries = cleanData({ ...boxEntries, [id]: boxEntry });
    setBoxEntries(newBoxEntries);
    debouncedSetLocalBoxEntries(newBoxEntries);
  }

  function onSetBoxEntries(boxEntries: { [id: string]: BoxEntry }) {
      setBoxEntries(boxEntries);
      setLocalBoxEntries(boxEntries);
  }

  return (
    <Router>
        <Switch>
            <Route exact path="/">
                <ConnectedListPage 
                    boxEntries={boxEntries}
                    setBoxEntries={onSetBoxEntries}
                    setBoxEntry={setBoxEntry}
                />
            </Route>
            <Route path="/entry/:id">
                <ConnectedBoxEntryPage 
                    boxEntries={boxEntries} 
                    setBoxEntry={setBoxEntry} 
                />
            </Route>
        </Switch>
    </Router>
  );
}

// const KNOWN_TRAINERS: { [trainerId: string]: { 
//   generationMark: GenerationMark, 
//   originalTrainer: string, 
//   language: Language 
// }} = {
//   "892265": {
//     generationMark: GenerationMark.Galar,
//     originalTrainer: "Chris",
//     language: Language.ENG
//   },
//   "21053": {
//     generationMark: GenerationMark.Pentagon,
//     originalTrainer: "Christopher",
//     language: Language.ENG
//   },
//   "23149": {
//     generationMark: GenerationMark.Pentagon,
//     originalTrainer: "Patrick",
//     language: Language.ENG
//   },
//   "373858": {
//     generationMark: GenerationMark.Clover,
//     originalTrainer: "Christopher",
//     language: Language.ENG
//   },
//   "29427": {
//     generationMark: GenerationMark.Pentagon,
//     originalTrainer: "Lily",
//     language: Language.ENG
//   },
//   "802040": {
//     generationMark: GenerationMark.Go,
//     originalTrainer: "Chris",
//     language: Language.ENG
//   }
// }

function cleanData(boxEntries:  { [id: string]: BoxEntry }) {
  return boxEntries;

  /*
  return Object.fromEntries(Object.values(boxEntries).map(entry => ([entry.id, {
    ...entry,
    ball: entry.ball?.length === 0 ? undefined : entry.ball,
    generationMark: entry.generationMark?.length === 0 ? undefined : entry.generationMark,
    language: entry.language?.length === 0 ? undefined : entry.language
  }])));
  */
  
  /*
  return Object.fromEntries(Object.values(boxEntries).map(entry => ([entry.id, {
    ...entry,
    generationMark: entry.generationMark || (KNOWN_TRAINERS[entry.trainerId] || {}).generationMark,
    language: entry.language || (KNOWN_TRAINERS[entry.trainerId] || {}).language,
    originalTrainer: (entry.originalTrainer === "" || entry.originalTrainer === undefined) ? (KNOWN_TRAINERS[entry.trainerId] || {}).originalTrainer : entry.originalTrainer,
  }])));
  */

  /*
  return Object.fromEntries(Object.values(boxEntries).map(entry => ([entry.id, {
    ...entry,
    obtainable: true,
    needsSlot: true
  }])));
  */

  /*
  return Object.fromEntries(Object.values(boxEntries).map(entry => ([entry.id, {
    ...entry,
    ball: entry.ball === undefined ? null : entry.ball
  }])));
  */

  /*
  return Object.fromEntries(Object.values(boxEntries).map(entry => ([entry.id, {
    ...entry,
    infoUrl: `https://serebii.net/pokemon/${entry.name.toLowerCase().replace(/\s/g, "")}`
  }])));
  */
}