import { useRef, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../hooks";

interface DropdownProps<T> {
    trigger: React.ReactNode
    items: { value: T, element: React.ReactNode, key: string }[]
    onSelect: (value: T) => void;
}

export function Dropdown<T>({ trigger, items, onSelect }: DropdownProps<T>) {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useClickOutside(containerRef, () => setOpen(false));

    return (
        <DropdownContainer ref={containerRef}>
            <Item onClick={() => setOpen(true)}>{trigger}</Item>
            { open && <Menu>{items.map(item => (
                <Item 
                    key={item.key} 
                    onClick={() => { setOpen(false); onSelect(item.value) }}
                >
                    {item.element}
                </Item>
            ))}</Menu> }
        </DropdownContainer>
    );
}

const Menu = styled.div`
    z-index: 100;
    position: absolute;
    top: -6px;
    left: -6px;
    border: 1px solid #e1e4e8;
    border-radius: 10px;
    background-color: white;
    padding: 5px;
    max-height: 206px;
    max-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 2px 5px rgb(15 17 17 / 15%);
    display: flex;
    flex-wrap: wrap;

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar {
        width: 20px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
        background-clip: content-box;
    }
`;

const DropdownContainer = styled.div`
    position: relative;
`;

const Item = styled.div`
    cursor: pointer;
`;