import { useEffect } from "react"
import { useHistory, useLocation } from "react-router"

export function useClickOutside<E extends Element>(
    ref: React.RefObject<E>, 
    onClickOutside: (event: Event) => void
) {
    useEffect(() => {
        let down = false

        const isInElement = (event: Event) => {
            return (
                !ref.current 
                || (event.target instanceof Element && ref.current.contains(event.target))
            )
        }

        const onMouseUp = (event: Event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!down || isInElement(event)) return
            onClickOutside(event)
            down = false
        }

        const onMouseDown = (event: Event) => {
            if (isInElement(event)) return
            down = true
        }

        document.addEventListener("mouseup", onMouseUp)
        document.addEventListener("mousedown", onMouseDown)

        return () => {
            document.removeEventListener("mouseup", onMouseUp)
            document.removeEventListener("mousedown", onMouseDown)
        }
    }, [ref, onClickOutside])
}

export function useSearch() {
    return new URLSearchParams(useLocation().search);
}

export function useSearchParam(paramName: string): [
    string | undefined, 
    (value: string | undefined) => void
] {
    const search = useSearch();
    const location = useLocation();
    const history = useHistory();

    const rawValue = search.get(paramName);
    const value = rawValue !== null ? rawValue : undefined;
    
    function setValue(newValue: string | undefined) {
        const newSearch = new URLSearchParams(search.toString());
        if (newValue === undefined) {
            newSearch.delete(paramName);
        } else {
            newSearch.set(paramName, newValue);
        }
        history.replace({
            pathname: location.pathname,
            search: newSearch.toString()
        });
    }

    return [value, setValue];
}