import styled from "styled-components";
import { Gender } from "../types";

interface GenderIconProps {
    gender: Gender
}

export function GenderIcon({ gender }: GenderIconProps) {
    return gender === Gender.Male ? <MaleIcon>♂</MaleIcon> : <FemaleIcon>♀</FemaleIcon>
}

const MaleIcon = styled.span`
    color: blue;
`;

const FemaleIcon = styled.span`
    color: red;
`;