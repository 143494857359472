import { useRef } from "react";
import styled from "styled-components";

interface LanguageIconProps {
    onLoadFile: (text: string) => void
}

export function LoadFileButton({ onLoadFile }: LanguageIconProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    async function fileSelected(files: FileList | null) {
        console.log(files, files?.length)
        if (files?.length === 1) {
            const file = files[0];
            const text = await file.text();
            onLoadFile(text);
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }
    }

    return (
        <>
            <InputButton htmlFor="file-upload">Load</InputButton>
            <input 
                id="file-upload" 
                type="file" 
                hidden 
                ref={inputRef}
                onChange={(e) => fileSelected(e.target.files)}
            />
        </>
    )
}

const InputButton = styled.label`
    box-sizing: border-box;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid;
    border-radius: 6px;
    appearance: none;
    color: #24292e;
    background-color: #fafbfc;
    border-color: rgba(27,31,35,0.15);
    box-shadow: 0 1px 0 rgba(27,31,35,0.04), inset 0 1px 0 hsla(0,0%,100%,0.25);
    transition: .2s cubic-bezier(.3,0,.5,1);
    transition-property: color,background-color,border-color;
    padding: 3px 12px;
    font-size: 12px;
    line-height: 20px;
    padding: 3px 12px;
    font-size: 12px;
    line-height: 20px;
    height: 28px;

    &:hover {
        background-color: #ededed;
    }
`;