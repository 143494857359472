import { Language } from "../types";
import { Dropdown } from "./Dropdown";
import { EmptyLanguageIcon } from "./EmptyLanguageIcon";
import { LanguageIcon } from "./LanguageIcon";

interface LanguageSelectProps {
    value: Language | undefined
    onChange: (value: Language | undefined) => void
}

export function LanguageSelect({ value, onChange }: LanguageSelectProps) {
    return (
        <Dropdown
            trigger={value ? <LanguageIcon language={value} /> : <EmptyLanguageIcon />}
            items={[
                value, 
                ...ALL_LANGUAGES.filter(b => b !== value)
            ].map(makeLanguageOption)}
            onSelect={onChange}
        />
    );
}

const ALL_LANGUAGES = [
    undefined,
    Language.ENG,
    Language.JPN,
    Language.CHS,
    Language.FRE,
    Language.SPA,
    Language.CHT,
    Language.GER,
    Language.KOR
];


function makeLanguageOption(language: Language | undefined) {
    if (language === undefined) {
        return {
            key: "none",
            value: language,
            element: <EmptyLanguageIcon />
        };
    } else {
        return {
            key: language.toString(),
            value: language,
            element: <LanguageIcon language={language} />
        };
    }
}